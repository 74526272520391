import React from 'react'
import SubHero from '../components/SubHero'
import Helmet from 'react-helmet'

import Layout from '../components/layout'

const HireATeam = () => (
  <Layout>
    <Helmet
      title="Hire A Team | NEXTGEN Code Company"
      meta={[
        {
          name: 'description',
          content:
            "Website's, SEO, search engine optimization, mobile app's, web applications, UI UX design, and more serving the Dallas, Lubbock and Corpus Christi Texas areas.",
        },
      ]}
    />
    <SubHero
      defaultBackURL={'/services'}
      defaultBackText={'Services'}
      title="Project Discovery"
      subtitle=""
    />
    <section className="section">
      <div className="container">
        <div className="columns">
          <div className="column">
            <form
              className="project-discovery-form"
              name="hire_a_team"
              method="post"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
            >
              <input
                type="hidden"
                name="form-name"
                value="hire_a_team"
                required
              />

              <div className="field">
                <label className="label">Name</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    name="name"
                    placeholder="Full name"
                    required
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">Business name</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    name="business_name"
                    placeholder="Business name"
                    required
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">Best way to contact you?</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    name="hire-a-team"
                    placeholder="Email or Phone Number"
                    required
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">
                  Describe what you are looking for
                </label>
                <div className="control">
                  <textarea
                    style={{ height: 'initial' }}
                    className="input"
                    type="text"
                    rows="4"
                    name="why"
                    placeholder="Or just tell us a short story..."
                    required
                  />
                </div>
              </div>

              <button className="btn btn--dark" type="submit">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default HireATeam
